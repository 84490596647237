import * as React from 'react'
import { Subscriptions } from '@modules/special-products/subscriptions/subscriptions'
import { GastroVouchers } from '@modules/special-products/gastro-vouchers/gastro-vouchers'
import { NoSpecialProducts } from '@modules/special-products/no-special-products'
import { PackagesVip } from '@modules/special-products/packages-vip/packages-vip'
import { ContentLoader } from '@components/loaders/content-loader'
import { SubscriptionContracts } from '@modules/special-products/subscription-contracts/subscription-contracts'
import { SubscriptionContractStatusesToShow } from '@modules/special-products/subscription-contracts/consts'
import { CashbackVouchers } from '@modules/special-products/cashback-vouchers/cashback-vouchers'
import { useGetCashbackVouchersQuery } from '@api/cashback-vouchers'
import { useClientDetails } from '@hooks/use-client-details'
import { CashbackVoucherStatusesToShow } from '@modules/special-products/cashback-vouchers/consts'
import { CashbackVoucher } from '@models/client'

export const SpecialProductsView = (): JSX.Element => {
  const {
    clientDetails: { subscriptions, gastro_cards, packages_vip, subscription_contracts, urls },
    isLoading: isFetchingClientDetails,
  } = useClientDetails()

  const { data: cashbackVouchers = [] } = useGetCashbackVouchersQuery(urls.cashback_vouchers, {
    skip: !urls.cashback_vouchers,
    refetchOnMountOrArgChange: true,
  })

  const cashbackVouchersToShow = cashbackVouchers.filter((voucher: CashbackVoucher) =>
    CashbackVoucherStatusesToShow.includes(voucher.status),
  )

  const hasSubscriptionContract = !!subscription_contracts.filter(subscriptionContract =>
    SubscriptionContractStatusesToShow.includes(subscriptionContract.status),
  ).length

  const isSpecialProductAdded =
    subscriptions.length ||
    gastro_cards.length ||
    packages_vip.length ||
    hasSubscriptionContract ||
    cashbackVouchersToShow.length

  const {
    gastroCards,
    gastroCards2024,
    gastroCards2025,
    gastroCards2026,
    gastroCards2027,
    gastroCoupons,
    gastroCardBoost,
    gastroCardsDynamic,
  } = React.useMemo(() => {
    const appendNew = (cummulated, newVoucher, kind) => ({
      ...cummulated,
      [kind]: [...cummulated[kind], newVoucher],
    })

    return gastro_cards.reduce(
      (vouchers, voucher) => {
        if (['normal', 'wholesale'].includes(voucher.kind)) return appendNew(vouchers, voucher, 'gastroCards')
        if (voucher.kind === 'gastro_coupon') return appendNew(vouchers, voucher, 'gastroCoupons')
        if (['gastro_card_2024', 'gastro_card_2024_wholesale'].includes(voucher.kind))
          return appendNew(vouchers, voucher, 'gastroCards2024')
        if (['gastro_card_2025', 'gastro_card_2025_wholesale'].includes(voucher.kind))
          return appendNew(vouchers, voucher, 'gastroCards2025')
        if (['gastro_card_boost', 'gastro_card_boost_wholesale'].includes(voucher.kind))
          return appendNew(vouchers, voucher, 'gastroCardBoost')

        if (voucher.kind === 'gastro_card_2026') return appendNew(vouchers, voucher, 'gastroCards2026')
        if (voucher.kind === 'gastro_card_2027') return appendNew(vouchers, voucher, 'gastroCards2027')
        if (voucher.kind === 'gastro_card_dynamic') return appendNew(vouchers, voucher, 'gastroCardsDynamic')

        return vouchers
      },
      {
        gastroCards: [],
        gastroCards2024: [],
        gastroCards2025: [],
        gastroCards2026: [],
        gastroCards2027: [],
        gastroCoupons: [],
        gastroCardBoost: [],
        gastroCardsDynamic: [],
      },
    )
  }, [gastro_cards])

  return (
    <div className="mt-3 d-flex flex-column gap-3 bg-light-azure mx-n2 mx-xl-0">
      <ContentLoader isLoading={isFetchingClientDetails}>
        {isSpecialProductAdded ? (
          <>
            {!!subscriptions.length && <Subscriptions subscriptions={subscriptions} />}
            {!!packages_vip.length && <PackagesVip packagesVip={packages_vip} />}
            {/*<Promocodes />*/}

            {!!gastroCards.length && (
              <GastroVouchers gastroCards={gastroCards} title="Twoje vouchery Podwójna Radość" />
            )}

            {!!gastroCards2024.length && (
              <GastroVouchers gastroCards={gastroCards2024} title="Twoje vouchery Podwójna Radość 2024" />
            )}

            {!!gastroCards2025.length && (
              <GastroVouchers gastroCards={gastroCards2025} title="Twoje vouchery Podwójna Radość 2025" />
            )}

            {!!gastroCards2026.length && (
              <GastroVouchers gastroCards={gastroCards2026} title="Twoje vouchery Podwójna Radość 2026/2027" />
            )}

            {!!gastroCards2027.length && (
              <GastroVouchers gastroCards={gastroCards2027} title="Twoje vouchery Podwójna Radość 2027" />
            )}

            {!!gastroCoupons.length && (
              <GastroVouchers gastroCards={gastroCoupons} title="Twoje vouchery Strzał w Dziesiątkę" />
            )}

            {!!gastroCardBoost.length && (
              <GastroVouchers gastroCards={gastroCardBoost} title="Twoje vouchery Doładowanie Smaku" />
            )}

            {!!gastroCardsDynamic.length && (
              <GastroVouchers gastroCards={gastroCardsDynamic} title="Twoje vouchery Fortuna Podwójnej Radości" />
            )}

            {hasSubscriptionContract && <SubscriptionContracts subscriptionContracts={subscription_contracts} />}

            {!!cashbackVouchersToShow.length && <CashbackVouchers cashbackVouchers={cashbackVouchers} />}
          </>
        ) : (
          <NoSpecialProducts />
        )}
      </ContentLoader>
    </div>
  )
}
